import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, concatMap } from 'rxjs/operators';
import * as LiabilityActions from './liability.actions';
import { CALCULATE_SERVICE, LoanRequestSubentityService, ICalculateService, LIABILITY_SERVICE } from '@oper-client/shared/data-access';
import { Liability } from '@oper-client/shared/data-model';

@Injectable()
export class LiabilityEffects {
	loadLiabilities$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(LiabilityActions.loadLiabilities),
			concatMap(({ loanRequestId, clientId }) =>
				this.liabilityService.getAll(loanRequestId, clientId).pipe(
					map((liabilities) => LiabilityActions.loadLiabilitiesSuccess({ liabilities })),
					catchError((error) => of(LiabilityActions.loadLiabilitiesFailure({ error })))
				)
			)
		);
	});

	loadLiability$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(LiabilityActions.loadLiability),
			concatMap(({ loanRequestId, clientId, liabilityId }) =>
				this.liabilityService.get(loanRequestId, clientId, liabilityId).pipe(
					map((liability) => LiabilityActions.loadLiabilitySuccess({ liability })),
					catchError((error) => of(LiabilityActions.loadLiabilityFailure({ error })))
				)
			)
		);
	});

	addLiability$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(LiabilityActions.addLiability),
			concatMap(({ loanRequestId, clientId, liability }) =>
				this.liabilityService.create(loanRequestId, clientId, liability).pipe(
					map((createdLiability) => LiabilityActions.addLiabilitySuccess({ liability: createdLiability })),
					catchError((error) => of(LiabilityActions.addLiabilityFailure({ error })))
				)
			)
		);
	});

	updateLiability$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(LiabilityActions.updateLiability),
			concatMap(({ type, loanRequestId, clientId, liability }) =>
				this.liabilityService.update(loanRequestId, clientId, +liability.id, liability.changes).pipe(
					map((updatedLiability) =>
						LiabilityActions.updateLiabilitySuccess({ liability: { id: updatedLiability.id, changes: updatedLiability } })
					),
					catchError((error) => of(LiabilityActions.updateLiabilityFailure({ error })))
				)
			)
		);
	});

	deleteLiability$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(LiabilityActions.deleteLiability),
			concatMap(({ loanRequestId, clientId, id }) =>
				this.liabilityService.delete(loanRequestId, clientId, id).pipe(
					map(() => LiabilityActions.deleteLiabilitySuccess({ id })),
					catchError((error) => of(LiabilityActions.deleteLiabilityFailure({ error })))
				)
			)
		);
	});

	calculateAmortization$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(LiabilityActions.calculateAmortization),
			concatMap(({ calculateAmortizationRequest }) =>
				this.calculateService.calculateAmortization(calculateAmortizationRequest).pipe(
					map((calculateAmortizationResponse) =>
						LiabilityActions.calculateAmortizationSuccess({ calculateAmortizationResponse })
					),
					catchError((error) => of(LiabilityActions.calculateAmortizationFailure({ error })))
				)
			)
		);
	});

	constructor(
		private readonly actions$: Actions,
		@Inject(LIABILITY_SERVICE) private readonly liabilityService: LoanRequestSubentityService<Liability>,
		@Inject(CALCULATE_SERVICE) private readonly calculateService: ICalculateService
	) {}
}
