export enum PrimaryLoanPurposeEnum {
	PURCHASE = 'purchase',
	NEW_BUILD = 'newBuild',
	BUYOUT = 'buyOut',
	FREE_USE = 'freeUse',
}

export enum AdditionalLoanPurposeEnum {
	RENOVATION = 'renovation',
	EXTEND = 'extend',
	MODERNISE = 'modernise',
	REFINANCE = 'refinance',
}

export const PrimaryLoanPurposes = Object.values(PrimaryLoanPurposeEnum);

export const AdditionalLoanPurposes = Object.values(AdditionalLoanPurposeEnum);
