import { createSelector } from '@ngrx/store';
import { getClientFeatureState } from '../client.selectors';
import { LIABILITIES_ENTITY_KEY, liabilityAdapter, LiabilityActionTypes } from './liability.reducer';

export const getLiabilityState = createSelector(getClientFeatureState, state => state[LIABILITIES_ENTITY_KEY]);

const selectors = liabilityAdapter.getSelectors();

export const getLiabilities = createSelector(getLiabilityState, selectors.selectAll);
export const getLiabilityMap = createSelector(getLiabilityState, selectors.selectEntities);

export const getActionStates = createSelector(getLiabilityState, state => state.actions);
export const getActionState = (actionType: LiabilityActionTypes) => createSelector(getActionStates, state => state[actionType]);

export const getCalculatedAmortization = createSelector(getLiabilityState, state => state.calculatedAmortization);
