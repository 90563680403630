import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { FinancialAsset, FireInsurance, Income, Liability } from '@oper-client/shared/data-model';

export const FINANCIAL_ASSET_SERVICE = new InjectionToken<LoanRequestSubentityService<FinancialAsset>>('CLIENT_SERVICE');
export const INCOME_SERVICE = new InjectionToken<LoanRequestSubentityService<Income>>('INCOME_SERVICE');
export const LIABILITY_SERVICE = new InjectionToken<LoanRequestSubentityService<Liability>>('LIABILITY_SERVICE');
export const FIRE_INSURANCE_SERVICE = new InjectionToken<LoanRequestSubentityService<FireInsurance>>('FIRE_INSURANCE_SERVICE');


export interface LoanRequestSubentityService<Entity> {
	getAll(loanRequestId: number, subEntityId: number, params?: HttpParams): Observable<Entity[]>;
	getAllByLoanRequestId?(loanRequestId: number, params?: HttpParams): Observable<Entity[]>;
	get(loanRequestId: number, subEntityId: number, id: number, params?: HttpParams): Observable<Entity>;
	create(loanRequestId: number, subEntityId: number, entity: Partial<Entity>, params?: HttpParams): Observable<Entity>;
	update?(loanRequestId: number, subEntityId: number, id: number, entity: Partial<Entity>, params?: HttpParams): Observable<Entity>;
	delete?(loanRequestId: number, subEntityId: number, id: number): Observable<void>;
}
