import { Inject, Pipe, PipeTransform } from '@angular/core';
import { CustomerInsights, CUSTOMER_INSIGHTS_CONFIG, ExistingRegions } from '@oper-client/shared/configuration';
import { Address } from '@oper-client/shared/data-model';

@Pipe({
	name: 'addressPreview',
})
export class AddressPreviewPipe implements PipeTransform {
	constructor(@Inject(CUSTOMER_INSIGHTS_CONFIG) public customerInsights: CustomerInsights) {}

	public transform(address: Address): string {
		if (!address) {
			return '';
		}

		switch (this.customerInsights?.region) {
			case ExistingRegions.HU:
				return `${address.zipCode ? address.zipCode : ''} ${address.city}, ${address.street} ${
					this.replaceDotAndTrim(address.houseNumber) + (address.floor ? '.' + this.replaceDotAndTrim(address.floor) : '')
				} ${address.box ?? ''}`.trim();
			default:
				return `${address.street} ${address.houseNumber}${address.box ? '/' + address.box : ''}, ${address.zipCode ?? ''} ${
					address.city
				}`.trim();
		}
	}

	private replaceDotAndTrim(text: string): string {
		return text?.replace('.', '')?.trim();
	}
}
