import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { CalculateAmortization, Liability } from '@oper-client/shared/data-model';

export const loadLiability = createAction(
	'[Liability/API] Load Liability',
	props<{ loanRequestId: number; clientId: number; liabilityId: number }>()
);
export const loadLiabilitySuccess = createAction('[Liability/API] Load Liability Success', props<{ liability: Liability }>());
export const loadLiabilityFailure = createAction('[Liability/API] Load Liability Failrue', props<{ error: HttpErrorResponse }>());

export const loadLiabilities = createAction('[Liability/API] Load Liabilities', props<{ loanRequestId: number; clientId: number }>());
export const loadLiabilitiesSuccess = createAction('[Liability/API] Load Liabilities Success', props<{ liabilities: Liability[] }>());
export const loadLiabilitiesFailure = createAction('[Liability/API] Load Liabilities Failure', props<{ error: HttpErrorResponse }>());

export const addLiability = createAction(
	'[Liability/API] Add Liability',
	props<{ loanRequestId: number; clientId: number; liability: Partial<Liability> }>()
);
export const addLiabilitySuccess = createAction('[Liability/API] Add Liability Success', props<{ liability: Liability }>());
export const addLiabilityFailure = createAction('[Liability/API] Add Liability Failure', props<{ error: HttpErrorResponse }>());

export const updateLiability = createAction(
	'[Liability/API] Update Liability',
	props<{ loanRequestId: number; clientId: number; liability: Update<Liability> }>()
);
export const updateLiabilitySuccess = createAction('[Liability/API] Update Liability Success', props<{ liability: Update<Liability> }>());
export const updateLiabilityFailure = createAction('[Liability/API] Update Liability Failure', props<{ error: HttpErrorResponse }>());

export const deleteLiability = createAction(
	'[Liability/API] Delete Liability',
	props<{ loanRequestId: number; clientId: number; id: number }>()
);
export const deleteLiabilitySuccess = createAction('[Liability/API] Delete Liability Success', props<{ id: number }>());
export const deleteLiabilityFailure = createAction('[Liability/API] Delete Liability Failure', props<{ error: HttpErrorResponse }>());

export const calculateAmortization = createAction(
	'[Liability/API] Calculate Amortization',
	props<{ calculateAmortizationRequest: CalculateAmortization }>()
);
export const calculateAmortizationSuccess = createAction(
	'[Liability/API] Calculate Amortization Success',
	props<{ calculateAmortizationResponse: CalculateAmortization }>()
);
export const calculateAmortizationFailure = createAction(
	'[Liability/API] Calculate Amortization Failure',
	props<{ error: HttpErrorResponse }>()
);

export const clearLiabilities = createAction('[Liability/API] Clear Liabilities');
