import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ResourceType } from '@oper-client/shared/data-model';
import { RESOURCES_FEATURE_KEY, ResourcesState } from './resources.reducer';

// Lookup the 'Resources' feature state managed by NgRx
export const getResourcesState = createFeatureSelector<ResourcesState>(RESOURCES_FEATURE_KEY);

export const getResources = createSelector(getResourcesState, (state: ResourcesState) => state.entities);
export const getResourceTypes = createSelector(getResourcesState, (state: ResourcesState) => {
	return state.entities ? Object.keys(state.entities) : [];
});

// export const getResourceEntitiesByType = () =>
// 	createSelector(getResourcesState, (state: ResourcesState, props: { kind: ResourceType }) => state.entities?.[props.kind]);
export const getResourceActionsByType = () =>
	createSelector(getResourcesState, (state: ResourcesState, props: { kind: ResourceType }) => state.actions?.[props.kind]);
