import { HttpErrorResponse } from '@angular/common/http';
import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { ActionState, setActionState, ActionTypes, initialActionState, CalculateAmortization, Liability } from '@oper-client/shared/data-model';

import * as LiabilityActions from './liability.actions';

export const LIABILITIES_ENTITY_KEY = 'liabilities';

export interface LiabilityState extends EntityState<Liability> {
	// additional entities state properties
	actions: LiabilityActionsState;
	calculatedAmortization: CalculateAmortization | null;
}

export type LiabilityActionTypes =
	| 'loadLiability'
	| 'loadLiabilities'
	| 'createLiability'
	| 'updateLiability'
	| 'calculateAmortization'
	| 'removeLiability';
export type LiabilityActionsState = Record<LiabilityActionTypes, ActionState>;

export const liabilityAdapter: EntityAdapter<Liability> = createEntityAdapter<Liability>();

export const initialState: LiabilityState = liabilityAdapter.getInitialState({
	// additional entity state properties
	actions: {
		loadLiability: initialActionState,
		loadLiabilities: initialActionState,
		createLiability: initialActionState,
		updateLiability: initialActionState,
		calculateAmortization: initialActionState,
		removeLiability: initialActionState,
	},
	calculatedAmortization: null,
});

function setActionStates(
	actionState: LiabilityActionsState,
	action: LiabilityActionTypes,
	actionType: ActionTypes,
	error: HttpErrorResponse = null
): LiabilityActionsState {
	return {
		...initialState.actions,
		[action]: setActionState(actionState[action], actionType, error),
	};
}

export const reducer = createReducer(
	initialState,
	on(LiabilityActions.addLiability, state => ({
		...state,
		actions: setActionStates(state.actions, 'createLiability', ActionTypes.loading),
	})),
	on(LiabilityActions.addLiabilitySuccess, (state, { liability }) =>
		liabilityAdapter.addOne(liability, { ...state, actions: setActionStates(state.actions, 'createLiability', ActionTypes.success) })
	),
	on(LiabilityActions.addLiabilityFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'createLiability', ActionTypes.failure, error),
	})),

	on(LiabilityActions.updateLiability, state => ({
		...state,
		actions: setActionStates(state.actions, 'updateLiability', ActionTypes.loading),
	})),
	on(LiabilityActions.updateLiabilitySuccess, (state, { liability }) =>
		liabilityAdapter.updateOne(liability, { ...state, actions: setActionStates(state.actions, 'updateLiability', ActionTypes.success) })
	),
	on(LiabilityActions.updateLiabilityFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'updateLiability', ActionTypes.failure, error),
	})),

	on(LiabilityActions.deleteLiability, state => ({
		...state,
		actions: setActionStates(state.actions, 'removeLiability', ActionTypes.loading),
	})),
	on(LiabilityActions.deleteLiabilitySuccess, (state, { id }) =>
		liabilityAdapter.removeOne(id, { ...state, actions: setActionStates(state.actions, 'removeLiability', ActionTypes.success) })
	),
	on(LiabilityActions.deleteLiabilityFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'removeLiability', ActionTypes.failure, error),
	})),

	on(LiabilityActions.loadLiability, state => ({
		...state,
		actions: setActionStates(state.actions, 'loadLiability', ActionTypes.loading),
	})),
	on(LiabilityActions.loadLiabilitySuccess, (state, { liability }) =>
		liabilityAdapter.upsertOne(liability, { ...state, actions: setActionStates(state.actions, 'loadLiability', ActionTypes.success) })
	),
	on(LiabilityActions.loadLiabilityFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadLiability', ActionTypes.failure, error),
	})),

	on(LiabilityActions.loadLiabilities, state => ({
		...state,
		actions: setActionStates(state.actions, 'loadLiabilities', ActionTypes.loading),
	})),
	on(LiabilityActions.loadLiabilitiesSuccess, (state, { liabilities }) =>
		liabilityAdapter.upsertMany(liabilities, {
			...state,
			actions: setActionStates(state.actions, 'loadLiabilities', ActionTypes.success),
		})
	),
	on(LiabilityActions.loadLiabilitiesFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadLiabilities', ActionTypes.failure, error),
	})),

	on(LiabilityActions.calculateAmortization, state => ({
		...state,
		actions: setActionStates(state.actions, 'calculateAmortization', ActionTypes.loading),
	})),
	on(LiabilityActions.calculateAmortizationSuccess, (state, { calculateAmortizationResponse }) => ({
		...state,
		actions: setActionStates(state.actions, 'calculateAmortization', ActionTypes.success),
		calculatedAmortization: calculateAmortizationResponse,
	})),
	on(LiabilityActions.calculateAmortizationFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'calculateAmortization', ActionTypes.failure, error),
		calculatedAmortization: null,
	})),

	on(LiabilityActions.clearLiabilities, state => liabilityAdapter.removeAll(state))
);
