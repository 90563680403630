import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { FeatureFlagService } from '../services/feature-flag.service';

@Injectable({ providedIn: 'root' })
export class FeatureFlagEnabledGuard implements CanActivate, CanLoad {
	constructor(private featureFlagService: FeatureFlagService, private readonly router: Router) {}

	canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
		return this.isFeatureFlagEnabled(route.data.flags).pipe(
			tap((isEnabled) => {
				if (!isEnabled && route.data.redirectTo) {
					this.router.navigate([route.data.redirectTo]);
				}
			})
		);
	}

	canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
		return this.isFeatureFlagEnabled(route.data.flags).pipe(
			tap((isEnabled) => {
				if (!isEnabled && route.data.redirectTo) {
					this.router.navigate([route.data.redirectTo]);
				}
			})
		);
	}

	private isFeatureFlagEnabled(flags: string[]): Observable<boolean> {
		if (!flags || !Array.isArray(flags)) {
			return of(true);
		}

		return this.featureFlagService.hasFeatureFlags(...flags).pipe(
			map((featureFlags) => {
				return Object.values(featureFlags).every((value) => !!value);
			})
		);
	}
}
