import { Injectable } from '@angular/core';
import { select, Store, Action } from '@ngrx/store';
import { filter, map, take } from 'rxjs/operators';

import { NormalizedResource, Resource, ResourceType } from '@oper-client/shared/data-model';

import * as fromResources from './resources.reducer';
import * as ResourcesSelectors from './resources.selectors';
import { Observable } from 'rxjs';

@Injectable()
export class ResourcesFacade {
	public readonly resources$ = this.store.pipe(
		select(ResourcesSelectors.getResources),
		filter((resources) => resources !== undefined)
	);

	constructor(private readonly store: Store<fromResources.ResourcesState>) {}

	dispatch(action: Action) {
		this.store.dispatch(action);
	}

	getResourceActionsByType({ kind }: { kind: ResourceType }) {
		return this.store.pipe(select(ResourcesSelectors.getResourceActionsByType(), { kind: kind }));
	}

	filterByResourceTypes(resourceTypes: ResourceType[]): Observable<NormalizedResource> {
		return this.resources$.pipe(
			filter((resources) => resourceTypes.every((resourceType) => resources[resourceType] != null)),
			map((resources) => {
				return resourceTypes.reduce((acc, resourceType) => {
					acc[resourceType] = resources[resourceType];
					return acc;
				}, {} as NormalizedResource);
			}),
			take(1)
		);
	}

	getResourceByType(resourceType: ResourceType): Observable<Resource[]> {
		return this.resources$.pipe(
			filter((resources) => resources[resourceType]?.length > 0),
			take(1),
			map((resources) => resources[resourceType])
		);
	}
}
